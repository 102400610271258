<template>
  <sub-page>
    <v-sheet class="d-flex justify-space-between wsRoundedLight pa-3 mb-5" :style="`border : 1px solid ${wsBACKGROUND}`">

      <div class="d-flex align-center">
        <ft-select
            v-model="selectedYear"
            :items="yearsSelect" >
          <h5 class="mr-3">
            {{ $t('Year_') }} : {{ selectedYear }}
            <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
          </h5>
        </ft-select>

        <ft-select
            v-model="selectedMonth"
            :items="monthsSelect" >
          <h5>
            {{ $t('Months') }} : {{ $t(`Month_${selectedMonth}`)  }}
            <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
          </h5>
        </ft-select>
      </div>


      <h4 :style="`color : ${wsACCENT}`"> {{ $t('PerMonthIncrease') }}:  {{ totalPerMonth }} $</h4>

    </v-sheet>

    <ws-data-table
        :headers="headers"
        :items="items"
    >

    </ws-data-table>
  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "newClients",
  data() {
    return {
      items : [],
      date : null,
      years : [],
      months : [],
      selectedYear : "2024",
      selectedMonth : 1
    }
  },
  computed : {
    totalPerMonth() {
      let sum = 0
      let items = this.items.map(el => parseInt(el.plan_price))
      items.forEach(el => {
        sum += el
      })
      return sum
    },
    yearsSelect() {
      let items = []
      this.years.forEach(el => {
        items.push( { text : el , value : el })
      })

      return items
    },
    monthsSelect() {
      if ( this.months.length === 0 ) {
        return []
      }
      let items = []
      let filteredMonths = this.months.filter(el => el.year === this.selectedYear)
      filteredMonths.forEach( item => {
        items.push({
          text : this.$t(`Month_${item.month}`) , value :item.month
        })
      })
      return items
    },
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Plan') , value : 'plan' },
        { text : this.$t('PlanPrice') , value : 'plan_price' },
        { text : this.$t('Months') , value : 'duration_months' },
        { text : this.$t('Sum') , value : 'payment_total' },
      ]
    }
  },
  methods : {
    ...mapActions('adminPayments', [
      'GET_NEW_BUSINESSES',
    ]),

    initDates() {
      // Get the current date
      const currentDate = new Date();

      const startDate = new Date(2022, 6); // Note: Months are 0-indexed, so 6 represents July.
      const monthsAndYears = [];
      const uniqueYears = [];

      while (startDate <= currentDate) {
        const month = startDate.getMonth() + 1; // Adding 1 to convert to 1-based index
        const year = startDate.getFullYear();

        // Add the month and year to the monthsAndYears array
        monthsAndYears.push({ month: month.toString(), year: year.toString() });

        // Add the year to the uniqueYears array if it's not already there
        if (!uniqueYears.includes(year.toString())) {
          uniqueYears.push(year.toString());
        }

        // Move to the next month
        startDate.setMonth(startDate.getMonth() + 1);

        this.months = monthsAndYears
        this.years = uniqueYears
      }

      this.selectedYear = uniqueYears[uniqueYears.length - 1]
      this.selectedMonth = monthsAndYears[monthsAndYears.length - 1].month

    },
    async initPage() {
      let data = {
        month : parseInt(this.selectedMonth),
        year : parseInt(this.selectedYear)
      }
      let result = await this.GET_NEW_BUSINESSES(data)
      if ( !result ) {
        return this.ERROR()
      }
      this.items = result.items
    }
  },
  watch : {
    selectedYear() {
      this.initPage()
    },
    selectedMonth() {
      this.initPage()
    }
  },
  mounted() {
    this.initDates()
  }
}
</script>

<style scoped>

</style>